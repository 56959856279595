<script>
    import dayjs from '$utils/day.js'
    import { _, locale } from 'svelte-i18n'
    import { slide } from 'svelte/transition'
    import { global } from '$src/state.svelte.js'
    import { DEFAULT_MANAGED_LOGO } from '$src/constants.js'
    import { dedupe, getDisplay } from '$utils/common'
    import { SLIDE_ANIM_MS } from '$src/constants.js'
    import { postPicture } from '$utils/api.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import logins from '$svr/providers/logins.json'
    import LoginProvider from '$lib/LoginProvider.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import UploadIcon from '$lib/icon/UploadIcon.svelte'
    import HandlePictureModal from '$lib/modal/HandlePictureModal.svelte'

    let {
        selected = $bindable(),
        expanded = $bindable(false),
        addExpanded = $bindable(false),
        continueWithProvider = () => {},
        handleDropdown = () => {},
    } = $props()

    let uploadPictureAjax = $state(false)
    let uploadedPicture = $state(null)

    let handlePictureAjax = $state(false)
    async function uploadPicture(picture) {
        handlePictureAjax = true
        const formDataObj = new FormData()
        formDataObj.append('file', picture)
        const res = await postPicture(formDataObj)
        global.data.release.pictures = res.pictures
        selected = global.data.release.pictures[0]
        expanded = false
        addExpanded = false
        uploadPictureAjax = false
        handlePictureAjax = false
    }
</script>

{#if uploadedPicture}
    <HandlePictureModal
        imgSrc={uploadedPicture}
        bind:ajaxRequest={handlePictureAjax}
        cancel={() => (uploadedPicture = null)}
        upload={(img) => {
            uploadPicture(img)
            uploadedPicture = null
        }}
    />
{/if}

<div data-test="picture-container" class="space-y-1">
    <label for="picture" class="text-sm font-medium">
        {$_('Profile Picture')}
    </label>
    {#if global.data.release?.managed?.picture}
        {@const meta = global.data?.release?.managed?._meta}
        <div
            class="bg-charcoal block flex h-12 items-center overflow-hidden rounded-md bg-opacity-10 px-4 dark:bg-opacity-100"
        >
            <span class="managed-rounded-square-sm">
                <img
                    src={meta.logo || DEFAULT_MANAGED_LOGO}
                    alt={meta.domain || 'logo'}
                    class="w-4.5 max-h-[18px] object-contain"
                />
            </span>
            <div class="ml-4 inline-flex items-center space-x-2.5">
                <img
                    src={global.data.release?.managed?.picture}
                    class="hide-alt-text h-8 w-8 flex-shrink-0 rounded-full object-cover"
                    alt="logo"
                />
                {#if meta.domain}
                    <span
                        >{$_('From {provider}', {
                            values: { provider: meta.domain },
                        })}</span
                    >
                {/if}
            </div>
        </div>
    {:else if global.data?.release?.update_scope}
        <div class="box-shadow mt-2 space-y-2 rounded-md p-2">
            <div
                data-test="emails-container"
                class="relative flex items-center"
            >
                <div class="w-full">
                    <ul class="mt-1 flex flex-col items-start space-y-2">
                        {#each dedupe(global.data?.release?.pictures) as pictureObj, index (pictureObj.picture)}
                            <li
                                data-test="picture-{index}"
                                class="flex w-full items-center"
                            >
                                <input
                                    id="picture-{pictureObj.picture}"
                                    name="picture"
                                    type="radio"
                                    bind:group={selected}
                                    value={pictureObj}
                                    class="form-radio text-charcoal h-4 w-4 rounded-full dark:text-[#808080]"
                                />
                                <label
                                    for="picture-{pictureObj.picture}"
                                    class="ml-2.5 inline-flex w-full items-center truncate"
                                >
                                    <div
                                        class="relative flex w-11/12 items-center justify-center gap-x-2"
                                    >
                                        <span
                                            class="block h-8 w-8 flex-shrink-0 overflow-hidden rounded-full bg-gray-100"
                                        >
                                            <img
                                                src={pictureObj.picture}
                                                class="hide-alt-text h-8 w-8 flex-shrink-0 rounded-full object-cover"
                                                title={pictureObj.source ===
                                                'default'
                                                    ? $_(
                                                          'Generic Hellō Profile Picture',
                                                      )
                                                    : pictureObj.source ===
                                                        'upload'
                                                      ? $_(
                                                            'Uploaded from device',
                                                        )
                                                      : $_('From {provider}', {
                                                            values: {
                                                                provider:
                                                                    pictureObj.source,
                                                            },
                                                        })}
                                                alt={pictureObj.source ===
                                                'default'
                                                    ? $_(
                                                          'Generic Hellō Profile Picture',
                                                      )
                                                    : pictureObj.source ===
                                                        'upload'
                                                      ? $_(
                                                            'Uploaded from device',
                                                        )
                                                      : $_('From {provider}', {
                                                            values: {
                                                                provider:
                                                                    pictureObj.source,
                                                            },
                                                        })}
                                            />
                                        </span>
                                        <span
                                            class="w-full truncate {$locale &&
                                            $locale.startsWith('ar')
                                                ? 'text-right'
                                                : 'text-left'}"
                                        >
                                            {#if pictureObj.source === 'default'}
                                                {$_(
                                                    'Generic Hellō Profile Picture',
                                                )}
                                            {:else if pictureObj.source === 'upload'}
                                                {#if Date.now() - Date.parse(pictureObj.createdAt) < 24 * 60 * 60 * 1000}
                                                    {$_('Uploaded today')}
                                                {:else}
                                                    {$_('Uploaded {date}', {
                                                        values: {
                                                            date: dayjs(
                                                                pictureObj.createdAt,
                                                            )
                                                                .locale($locale)
                                                                .fromNow(),
                                                        },
                                                    })}
                                                {/if}
                                            {:else}
                                                {$_('From {provider}', {
                                                    values: {
                                                        provider:
                                                            pictureObj.source ||
                                                            getDisplay(
                                                                pictureObj.slug,
                                                            ),
                                                    },
                                                })}

                                                {#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(pictureObj.slug) && pictureObj.label}
                                                    ({pictureObj.label})
                                                {/if}

                                                {#if pictureObj.label !== 'gravatar'}
                                                    ({Date.now() -
                                                        Date.parse(
                                                            pictureObj.updatedAt ||
                                                                pictureObj.createdAt,
                                                        ) <
                                                    24 * 60 * 60 * 1000
                                                        ? pictureObj.updatedAt
                                                            ? $_(
                                                                  'Updated today',
                                                              )
                                                            : $_('Added today')
                                                        : $_(
                                                              pictureObj.updatedAt
                                                                  ? 'Updated {date}'
                                                                  : 'Added {date}',
                                                              {
                                                                  values: {
                                                                      date: dayjs(
                                                                          pictureObj.updatedAt ||
                                                                              pictureObj.createdAt,
                                                                      )
                                                                          .locale(
                                                                              $locale,
                                                                          )
                                                                          .fromNow(),
                                                                  },
                                                              },
                                                          )})
                                                {/if}
                                            {/if}
                                        </span>
                                    </div>
                                </label>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>

            <div class="relative">
                <Dropdown
                    hasBackground={false}
                    dataTest="add-provider-btn"
                    ariaLabel="Add another picture"
                    expanded={addExpanded}
                    onclick={() => handleDropdown('addPicture')}
                >
                    <div
                        class="flex h-12 items-center px-4 text-left"
                        aria-hidden="true"
                    >
                        {$_('Add another picture')}
                    </div>

                    {#if addExpanded}
                        <ul
                            class="space-y-2 p-2"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            <li>
                                <button
                                    data-test="upload-picture-btn"
                                    onclick={() => {
                                        uploadPictureAjax = true
                                        document
                                            .getElementById(
                                                'picture-upload-ref',
                                            )
                                            .click()
                                        setTimeout(() => {
                                            uploadPictureAjax = false
                                        }, 2022)
                                        /**
                                         * TODO
                                         * This is a hack to prevent closing of picture dropdown,
                                         * clickOutside is fired because of input file window?
                                         */
                                        setTimeout(() => {
                                            expanded = true
                                        }, 1)
                                    }}
                                    disabled={uploadPictureAjax}
                                    class="btn-background relative flex w-full items-center overflow-hidden disabled:opacity-60 {uploadPictureAjax
                                        ? 'justify-center'
                                        : 'justify-start'} px-4"
                                >
                                    <div
                                        class="flex w-full items-center justify-center"
                                    >
                                        {#if uploadPictureAjax}
                                            <SpinnerIcon
                                                css="h-5 w-5 text-white dark:text-[#d4d4d4]"
                                            />
                                        {:else}
                                            <UploadIcon />
                                            <span
                                                class="block text-left {$locale &&
                                                $locale.startsWith('ar')
                                                    ? 'mr-4'
                                                    : 'ml-4'}"
                                            >
                                                {$_('Upload from device')}
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="flex-shrink-0 {$locale &&
                                                $locale.startsWith('ar')
                                                    ? 'mr-auto'
                                                    : 'ml-auto'} h-4.5 group-focus:stroke-3 group-hover:stroke-3 -rotate-90 transform text-white opacity-80 dark:text-[#d4d4d4]"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                stroke-width="2"
                                                class:rotate-90={$locale &&
                                                    $locale.startsWith('ar')}
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M19 9l-7 7-7-7"
                                                />
                                            </svg>
                                        {/if}
                                        <input
                                            id="picture-upload-ref"
                                            type="file"
                                            accept="image/*"
                                            name="logo"
                                            hidden
                                            onchange={(e) =>
                                                (uploadedPicture =
                                                    URL.createObjectURL(
                                                        e.target.files[0],
                                                    ))}
                                            oncancel={() =>
                                                (uploadPictureAjax = false)}
                                        />
                                    </div>
                                </button>
                            </li>
                            {#each logins.filter((i) => i.claims.picture) as provider}
                                <LoginProvider
                                    {provider}
                                    prefix={Array.isArray(
                                        global.data?.accounts,
                                    ) &&
                                    global.data?.accounts.includes(
                                        provider.slug,
                                    )
                                        ? 'Get another picture from'
                                        : 'Get picture from'}
                                    onclick={(e) =>
                                        continueWithProvider(
                                            provider.slug,
                                            'picture',
                                            e,
                                        )}
                                />
                            {/each}
                        </ul>
                    {/if}
                </Dropdown>
            </div>
        </div>
    {:else}
        <Dropdown
            hasBackground={false}
            dataTest="picture-dropdown-btn"
            id="picture-dropdown"
            ariaLabel="Profile Picture"
            {expanded}
            onclick={() => handleDropdown('picture')}
        >
            <div class="flex h-12 w-full items-center justify-between px-2">
                <div
                    data-test="selected-picture"
                    class="flex w-11/12 items-center justify-center gap-x-4"
                >
                    <img
                        src={selected.picture}
                        title={selected.source === 'default'
                            ? $_('Generic Hellō Profile Picture')
                            : selected.source === 'upload'
                              ? $_('Uploaded from device')
                              : $_('From {provider}', {
                                    values: {
                                        provider: selected.source,
                                    },
                                })}
                        alt={selected.source === 'default'
                            ? $_('Generic Hellō Profile Picture')
                            : selected.source === 'upload'
                              ? $_('Uploaded from device')
                              : $_('From {provider}', {
                                    values: {
                                        provider: selected.source,
                                    },
                                })}
                        class="hide-alt-text h-8 w-8 flex-shrink-0 rounded-full object-cover"
                    />
                    <span
                        class="w-full truncate {$locale &&
                        $locale.startsWith('ar')
                            ? 'text-right'
                            : 'text-left'}"
                    >
                        {#if selected.source === 'default'}
                            {$_('Generic Hellō Profile Picture')}
                        {:else if selected.source === 'upload'}
                            {Date.now() - Date.parse(selected.createdAt) <
                            24 * 60 * 60 * 1000
                                ? $_('Uploaded today')
                                : $_('Uploaded {date}', {
                                      values: {
                                          date: dayjs(selected.createdAt)
                                              .locale($locale)
                                              .fromNow(),
                                      },
                                  })}
                        {:else}
                            {$_('From {provider}', {
                                values: {
                                    provider:
                                        selected.source ||
                                        getDisplay(selected.slug),
                                },
                            })}

                            {#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(selected.slug) && selected.label}
                                ({selected.label})
                            {/if}

                            {#if selected.label !== 'gravatar'}
                                ({Date.now() -
                                    Date.parse(
                                        selected.updatedAt ||
                                            selected.createdAt,
                                    ) <
                                24 * 60 * 60 * 1000
                                    ? selected.updatedAt
                                        ? $_('Updated today')
                                        : $_('Added today')
                                    : $_(
                                          selected.updatedAt
                                              ? 'Updated {date}'
                                              : 'Added {date}',
                                          {
                                              values: {
                                                  date: dayjs(
                                                      selected.updatedAt ||
                                                          selected.createdAt,
                                                  )
                                                      .locale($locale)
                                                      .fromNow(),
                                              },
                                          },
                                      )})
                            {/if}
                        {/if}
                    </span>
                </div>
            </div>
            {#if expanded}
                {@const pictures = Array.isArray(global.data?.release?.pictures)
                    ? dedupe(global.data.release.pictures, 'picture').filter(
                          (i) => i.picture !== selected.picture,
                      )
                    : []}
                <div
                    class="px-indent flex w-full flex-col text-left"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <ul
                        class="mt-0.5 {pictures.length
                            ? 'border-y'
                            : 'border-t'} divide-y divide-[#808080] border-[#808080]"
                    >
                        {#each pictures as pictureObj, index (pictureObj.picture)}
                            <li>
                                <button
                                    data-test={`picture-${index}`}
                                    class="flex h-12 w-full flex-shrink-0 items-center justify-start"
                                    onclick={() => {
                                        selected = pictureObj
                                        expanded = false
                                    }}
                                >
                                    <div
                                        class="relative flex w-11/12 items-center justify-center gap-x-4"
                                    >
                                        <span
                                            class="block h-8 w-8 flex-shrink-0 overflow-hidden rounded-full bg-gray-100"
                                        >
                                            <img
                                                src={pictureObj.picture}
                                                class="hide-alt-text h-8 w-8 flex-shrink-0 rounded-full object-cover"
                                                title={pictureObj.source ===
                                                'default'
                                                    ? $_(
                                                          'Generic Hellō Profile Picture',
                                                      )
                                                    : pictureObj.source ===
                                                        'upload'
                                                      ? $_(
                                                            'Uploaded from device',
                                                        )
                                                      : $_('From {provider}', {
                                                            values: {
                                                                provider:
                                                                    pictureObj.source,
                                                            },
                                                        })}
                                                alt={pictureObj.source ===
                                                'default'
                                                    ? $_(
                                                          'Generic Hellō Profile Picture',
                                                      )
                                                    : pictureObj.source ===
                                                        'upload'
                                                      ? $_(
                                                            'Uploaded from device',
                                                        )
                                                      : $_('From {provider}', {
                                                            values: {
                                                                provider:
                                                                    pictureObj.source,
                                                            },
                                                        })}
                                            />
                                        </span>
                                        <span
                                            class="w-full truncate {$locale &&
                                            $locale.startsWith('ar')
                                                ? 'text-right'
                                                : 'text-left'}"
                                        >
                                            {#if pictureObj.source === 'default'}
                                                {$_(
                                                    'Generic Hellō Profile Picture',
                                                )}
                                            {:else if pictureObj.source === 'upload'}
                                                {#if Date.now() - Date.parse(pictureObj.createdAt) < 24 * 60 * 60 * 1000}
                                                    {$_('Uploaded today')}
                                                {:else}
                                                    {$_('Uploaded {date}', {
                                                        values: {
                                                            date: dayjs(
                                                                pictureObj.createdAt,
                                                            )
                                                                .locale($locale)
                                                                .fromNow(),
                                                        },
                                                    })}
                                                {/if}
                                            {:else}
                                                {$_('From {provider}', {
                                                    values: {
                                                        provider:
                                                            pictureObj.source ||
                                                            getDisplay(
                                                                pictureObj.slug,
                                                            ),
                                                    },
                                                })}

                                                {#if ['gravatar', 'twitter', 'github', 'gitlab', 'instagram'].includes(pictureObj.slug) && pictureObj.label}
                                                    ({pictureObj.label})
                                                {/if}

                                                {#if pictureObj.label !== 'gravatar'}
                                                    ({Date.now() -
                                                        Date.parse(
                                                            pictureObj.updatedAt ||
                                                                pictureObj.createdAt,
                                                        ) <
                                                    24 * 60 * 60 * 1000
                                                        ? pictureObj.updatedAt
                                                            ? $_(
                                                                  'Updated today',
                                                              )
                                                            : $_('Added today')
                                                        : $_(
                                                              pictureObj.updatedAt
                                                                  ? 'Updated {date}'
                                                                  : 'Added {date}',
                                                              {
                                                                  values: {
                                                                      date: dayjs(
                                                                          pictureObj.updatedAt ||
                                                                              pictureObj.createdAt,
                                                                      )
                                                                          .locale(
                                                                              $locale,
                                                                          )
                                                                          .fromNow(),
                                                                  },
                                                              },
                                                          )})
                                                {/if}
                                            {/if}
                                        </span>
                                    </div>
                                </button>
                            </li>
                        {/each}
                    </ul>
                    <ul class="my-2 space-y-2">
                        <!-- Picture upload start -->
                        <li>
                            <button
                                data-test="upload-picture-btn"
                                onclick={() => {
                                    uploadPictureAjax = true
                                    document
                                        .getElementById('picture-upload-ref')
                                        .click()
                                    setTimeout(() => {
                                        uploadPictureAjax = false
                                    }, 2022)
                                    /**
                                     * TODO
                                     * This is a hack to prevent closing of picture dropdown,
                                     * clickOutside is fired because of input file window?
                                     */
                                    setTimeout(() => {
                                        expanded = true
                                    }, 1)
                                }}
                                disabled={uploadPictureAjax}
                                class="btn-background relative flex w-full items-center overflow-hidden disabled:opacity-60 {uploadPictureAjax
                                    ? 'justify-center'
                                    : 'justify-start'} px-4"
                            >
                                <div
                                    class="flex w-full items-center justify-center"
                                >
                                    {#if uploadPictureAjax}
                                        <SpinnerIcon
                                            css="h-5 w-5 text-white dark:text-[#d4d4d4]"
                                        />
                                    {:else}
                                        <UploadIcon />
                                        <span
                                            class="block text-left {$locale &&
                                            $locale.startsWith('ar')
                                                ? 'mr-4'
                                                : 'ml-4'}"
                                        >
                                            {$_('Upload from device')}
                                        </span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="flex-shrink-0 {$locale &&
                                            $locale.startsWith('ar')
                                                ? 'mr-auto'
                                                : 'ml-auto'} h-4.5 group-focus:stroke-3 group-hover:stroke-3 -rotate-90 transform text-white opacity-80 dark:text-[#d4d4d4]"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            stroke-width="2"
                                            class:rotate-90={$locale &&
                                                $locale.startsWith('ar')}
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                d="M19 9l-7 7-7-7"
                                            />
                                        </svg>
                                    {/if}
                                    <input
                                        id="picture-upload-ref"
                                        type="file"
                                        accept="image/*"
                                        name="logo"
                                        hidden
                                        onchange={(e) =>
                                            (uploadedPicture =
                                                URL.createObjectURL(
                                                    e.target.files[0],
                                                ))}
                                        oncancel={() =>
                                            (uploadPictureAjax = false)}
                                    />
                                </div>
                            </button>
                        </li>
                        {#each logins.filter((i) => i.claims.picture) as provider}
                            <li>
                                <LoginProvider
                                    onclick={(e) =>
                                        continueWithProvider(
                                            provider.slug,
                                            'picture',
                                            e,
                                        )}
                                    {provider}
                                    prefix="Get picture from"
                                />
                            </li>
                        {/each}
                    </ul>
                </div>
            {/if}
        </Dropdown>
    {/if}
</div>

<style>
    @media (prefers-color-scheme: dark) {
        .box-shadow {
            box-shadow: 0 0 0 1px #808080;
        }
    }

    @media (prefers-color-scheme: light) {
        .box-shadow {
            box-shadow: 0 0 0 1px #303030;
        }
    }

    ul li button:hover,
    ul li button:focus {
        box-shadow:
            0px -2px 0px rgb(128, 128, 128),
            0px 2px 0px rgb(128, 128, 128);
    }
</style>
